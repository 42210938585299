
<template>
    <div class="article-home">
        <div class="left">
            <md-button @click="openQAQuestionModal" class="md-accent md-raised" style="margin:0">Open A Ticket</md-button>

            <md-input-container>
                <label for>Search</label>
                <md-autocomplete :debounce="150" :fetch="search" @selected="searchSelected" print-attribute="title" v-model="q"></md-autocomplete>
            </md-input-container>

            <md-list>
                <md-list-item :key="ix" md-expand-multiple ref="menuitem" v-for="(parent,ix) in groupCategories">
                    <md-icon>people</md-icon>
                    <span>{{parent.name}}</span>
                    <md-list-expand>
                        <md-list>
                            <md-list-item :key="iix" class="md-inset" v-for="(child,iix) in parent.categories">
                                <router-link :to="`/helpcenter/category/${parent.slug}/${child.slug}`">{{child.name}}</router-link>
                            </md-list-item>
                        </md-list>
                    </md-list-expand>
                </md-list-item>
            </md-list>
        </div>
        <div class="right">
            <article-home-list :category="getCategory(parentcategory,category) && getCategory(parentcategory,category).name " :list="articles" v-if="category && !article"></article-home-list>
            <article-detail :article="currentArticle" v-if="article"></article-detail>
        </div>
        <q-a-question-modal ref="qaQuestionModal"></q-a-question-modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ArticleDetail from "@/components/Article/ArticleDetail";
import ArticleHomeList from "@/components/Article/ArticleHomeList";
import QAQuestionModal from "@/components/QA/QAQuestionModal.vue";

export default {
    props: {
        parentcategory: {
            type: String
        },
        category: {
            type: String
        },
        article: {
            type: String
        }
    },
    components: {
        ArticleDetail,
        ArticleHomeList,
        QAQuestionModal
    },
    data() {
        return {
            q: "",
            articles: [],
            currentArticle: {},
            groupCategories: []
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        parentcategoryAndCategory() {
            return this.parentcategory + this.category;
        },
        getCategory() {
            return (pslug, slug) => {
                let p = this.groupCategories.find(g => g.slug == pslug);
                if (p) {
                    return p.categories.find(c => c.slug == slug);
                }
                return null;
            };
        }
    },
    watch: {
        parentcategoryAndCategory: {
            immediate: true,
            handler() {
                this.loadArticles();
            }
        },
        article: {
            immediate: true,
            handler() {
                this.loadArticle();
            }
        }
    },
    methods: {
        async getGroupCategories() {
            let result = await this.$store.dispatch("crud/get", {
                api: "articles/categories",
                params: {
                    group: 1
                }
            });

            // hide vendor topic
            this.groupCategories = result.filter(r => {
                if (!this.hasRole("vendor")) {
                    if (/vendor/i.test(r.name)) {
                        return false;
                    }
                }
                return true;
            });
        },
        async loadArticles() {
            if (this.category) {
                let result = await this.$store.dispatch("crud/get", {
                    api: "articles",
                    params: {
                        category_parent_slug: this.parentcategory,
                        category_slug: this.category,
                        select: "-content",
                        pageSize: 0
                    }
                });
                this.articles = result.data;
            }
        },
        async loadArticle() {
            if (this.article) {
                let result = await this.$store.dispatch("crud/get", {
                    api: "articles",
                    params: {
                        slug: this.article,
                        pageSize: 0
                    }
                });
                if (result.data.length) {
                    this.currentArticle = result.data[0];
                }
            }
        },
        goDefault() {
            if (this.groupCategories.length) {
                let group = this.groupCategories[0];
                if (group.categories.length) {
                    let category = group.categories[0];
                    this.$router.push(`/helpcenter/category/${group.slug}/${category.slug}`);
                }
            }
        },
        async search(params) {
            let result = await this.$store.dispatch("crud/get", {
                api: "articles",
                params: {
                    select: "-content",

                    q: params.q
                }
            });
            return result.data;
        },
        searchSelected(item) {
            this.$router.push(`/helpcenter/article/${item.slug}`);
        },
        openQAQuestionModal() {
            this.$refs["qaQuestionModal"].open();
        }
    },
    async created() {
        await this.getGroupCategories();
        if (!this.category && !this.category) {
            this.goDefault();
        }

        // open menu
        setTimeout(() => {
            if (this.$refs["menuitem"]) {
                [].concat(this.$refs["menuitem"]).forEach(comp => (comp.active = true));
            }
        }, 10);
    }
};
</script>
<style lang="less">
.article-home {
    display: flex;
    flex-wrap: wrap;
    .left {
        width: 300px;
        margin: 5px 20px;
        .md-input-container {
            margin: 10px 0px;
            width: auto;
            label {
                left: 5px;
            }
        }
    }
    .right {
        flex: 1;
        margin: 20px 5%;
    }
}
</style>

