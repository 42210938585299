<template>
    <md-dialog class="contact" ref="contact">
        <edit :fs="contactFields" api="notifications/contactus" title="Contact Us" customClassName="none" @saved="saved"></edit>
    </md-dialog>
</template>

<script>
import { contactFields } from "@/config";
import Edit from "@/components/Edit";
import { mapGetters } from "vuex";
export default {
    components: {
        Edit
    },
    computed: {
        ...mapGetters({
            user: "user/user"
        }),
        contactFields() {
            if (this.user) {
                let emailField = contactFields.find(f => f.data == "from");
                emailField.default = this.user.email;
            }
            return contactFields;
        }
    },
    methods: {
        saved(result) {
            this.$store.commit("setMessage", "Email sent successfully");
            this.close();
        },
        open() {
            this.$refs["contact"].open();
        },
        close() {
            this.$refs["contact"].close();
        }
    }
};
</script>
<style lang="less">
</style>
