<template>
    <div class="article-home-list">
        <md-card>
            <md-card-header>
                <h3 class="md-title">{{category}}</h3>
            </md-card-header>
            <md-card-content>
                <md-list>
                    <md-list-item :key="ix" v-for="(article,ix) in list">
                        <router-link :to="`/helpcenter/article/${article.slug}`">
                            <md-icon>send</md-icon>
                            <span>{{article.title}}</span>
                        </router-link>
                    </md-list-item>
                </md-list>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array
        },
        category: {
            type: String
        }
    }
};
</script>
<style lang="less">
.article-home-list {
    .md-card {
        min-height: 70vh;
    }
    .md-list {
        .md-list-item {
            a {
                // color: orange;
            }
        }
    }
}
</style>
