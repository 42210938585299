<template>
    <div class="article-detail">
        <md-card>
            <md-card-header>
                <h3>{{article.title}}</h3>
                <small class="date">{{article.updatedAt | date}}</small>
            </md-card-header>
            <md-card-content>
                <div class="detail" v-html="article.content"></div>
            </md-card-content>
            <md-card-content>
                <div class="help">
                    <span>Still need help?</span>
                    <a @click.prevent="openQAQuestionModal()" href="#">Contact Us</a>
                </div>
            </md-card-content>
        </md-card>
        <!-- <contact ref="contact"></contact> -->
        <q-a-question-modal ref="qaQuestionModal" title="Contact Us"></q-a-question-modal>
    </div>
</template>
<script>
import Contact from "@/components/modal/Contact";
import QAQuestionModal from "@/components/QA/QAQuestionModal.vue";

export default {
    props: {
        article: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        QAQuestionModal
        // Contact
    },
    methods: {
        contactUs() {
            this.$refs.contact.open();
        },
        openQAQuestionModal() {
            this.$refs["qaQuestionModal"].open();
        }
    }
};
</script>
<style lang="less">
.article-detail {
    .md-card {
        min-height: 70vh;
        .md-card-header {
        }
    }
    .help {
        font-style: italic;
    }
}
</style>

